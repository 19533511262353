import { Tag } from "antd";
import React, { useState } from "react";

import { Collapse, Table } from "antd";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deleteCotRuleSetAction,
  updateTrustGroupRuleAction,
} from "../../redux/action/action";
import { expandedIcon } from "../../utils/expanded";
import { rulesColumns } from "../Tables/trustedGroupColumn";
import { FcInfo } from "react-icons/fc";
import { IconButton, Tooltip } from "@mui/material";
const { Panel } = Collapse;
const nestedColumns = [
  {
    title: "Endpoint Name",
    dataIndex: "name",
  },
  {
    title: "IP Address",
    dataIndex: "ip_address",
  },

  {
    title: "Status",
    dataIndex: "isConnected",
    render: (data) => {
      return (
        <div>
          {data ? (
            <Tag color="green">Connected</Tag>
          ) : (
            <Tag color="red">Disconnected</Tag>
          )}
        </div>
      );
    },
  },
];
const TrustGroupTable = ({ records, control, watch, param }) => {
  const [open, setOpen] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const onDelete = (rule_id) => {
    dispatch(deleteCotRuleSetAction(rule_id, id));
  };
  const handlePanelChange = (key) => {
    setOpen(key.join(","));
  };

  const nestedEndpointTable = (record) => {
    return (
      <div className="p-3">
        <Table
          dataSource={record?.connectedDevices ? record?.connectedDevices : []}
          columns={nestedColumns}
          pagination={{
            hideOnSinglePage: true,
          }}
        />
      </div>
    );
  };

  const colums = [
    {
      title: "Device Name",
      dataIndex: "device_name",
    },
    {
      title: (
        <div>
          {" "}
          Trust Level{" "}
          <span>
            <Tooltip
              title={
                <div>
                  <div className="mt-3">
                    <b >Level 1 - Basic Trust : </b>
                    <span>
                      This is the entry-level trust designation within the
                      Circle of Trust. It represents the minimum level of trust
                      required for a device or user to be part of the CoT
                      ecosystem.
                    </span>
                  </div>
                  <div className="mt-3">
                    <b>Level 2 - Intermediate Trust : </b>
                    <span>
                      This level represents a higher degree of trust within the
                      CoT. Users or devices with Level 2 trust have proven a
                      greater need for access and possess more robust
                      authentication credentials.
                    </span>
                  </div>
                  <div className="mt-3">
                    <b>Level 3 - High Trust : </b>
                    <span>
                      This is the highest level of trust within the Circle of
                      Trust framework, reserved for users or devices with the
                      most critical roles and responsibilities. It represents
                      full integration and access within the CoT ecosystem.
                    </span>
                  </div>
                </div>
              }
            >
              <IconButton>
                <FcInfo style={{ cursor: "pointer" }} fontSize={12} />{" "}
              </IconButton>
            </Tooltip>{" "}
          </span>
        </div>
      ),
      dataIndex: "trustLevel",
    },
    {
      title: "End User",
      dataIndex: "endUser",
    },
    {
      title: "Ip Address",
      dataIndex: "nebula_ip",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (data, record) => {
        return (
          <div>
            {data == "Active" ? (
              <Tag color="green">{data}</Tag>
            ) : (
              <Tag color="red">{data}</Tag>
            )}
          </div>
        );
      },
    },
  ];
  const onStatusChange = (data) => {
    dispatch(updateTrustGroupRuleAction(data, id));
  };
  return (
    <div>
      <Collapse accordion onChange={handlePanelChange}>
        <Panel
          header={
            <div className="d-flex justify-content-between">
              <div className="text-grey">
                <span>Node</span>
              </div>

              {open == "1" && (
                <div className="text-end">
                  <button
                    type="submit"
                    className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    onClick={() => navigate(`${location.pathname}/addnode`)}
                  >
                    Edit Node
                  </button>
                </div>
              )}
            </div>
          }
          key="1"
        >
          <Table
            dataSource={records.nodes}
            columns={colums}
            className="custom-table-1"
            expandable={{
              expandedRowRender: nestedEndpointTable,
              expandIcon: expandedIcon,
            }}
            pagination={false}
            // scroll={{ x: 1400 }}
            rowKey={"device_id"}
            size="middle"
          />
        </Panel>
        <Panel
          header={
            <div className="d-flex justify-content-between">
              <div className="text-grey">
                <span>Rules</span>
              </div>

              {open == "2" && (
                <div className="text-end">
                  <button
                    type="submit"
                    className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    onClick={() => navigate(`${location.pathname}/addrule`)}
                  >
                    Add Rule
                  </button>
                </div>
              )}
            </div>
          }
          key="2"
        >
          <Table
            columns={rulesColumns({
              control,
              param,
              onDelete,
              id,
              navigate,
              onStatusChange,
            })}
            dataSource={records.rules}
            className="custom-table-1"
            // size="middle"
          />
        </Panel>
      </Collapse>

      {/* <Temp /> */}
    </div>
  );
};

export default TrustGroupTable;
